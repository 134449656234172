import React from "react"
import Layout from '~containers/layout/layout'

export default () => (
    <Layout>
        {
          //<Banner/>
        //<Blog/>
      }
    </Layout>
)
